import { AfterContentInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { locale } from '@util/locale/locale';
import { Router } from '@angular/router';
import { AccountsService } from '@services/accounts.service';
import { Account } from '@interfaces/account';
import { combineLatest, Observable, of } from 'rxjs';
import { NotificationService } from '@services/notification.service';
import { Notification } from '@interfaces/notifications';
import { MatDialog } from '@angular/material/dialog';
import { ReservationComponent } from '@web/app/components/dialogs/reservation/reservation.component';
import { ReservationsService } from '@services/reservations.service';
import { LoadingComponent } from '@web/app/components/loading/loading.component';
import { environment } from '@env/environment';
import { switchMap } from 'rxjs/operators';
import { GoogleAuthService } from '@services/dws/google-auth.service';
import { HostCompanyService } from '@services/host-company.service';
import { observe } from '@web/util/loading/loading';
import { feature } from 'projects/web/src/app/modules/feature-toggle/feature-toggle.util';
import { AuthenticateAdminService, Authorization } from '@services/dws';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { MatMenu } from '@angular/material/menu';
import { toast } from '@web/util/toast';
import { tr } from '@util/tr';
import { OutlookAuthService } from '@services/dws/outlook-auth.service';
import { ReservationDetailService } from '@services/dws/scheduling/reservation-detail/reservation-detail.service';
import { PreordersService } from '@services/dws/preorders.service';
import * as moment from 'moment';
import { SelfAccount } from '@interfaces/dws/self-account';
import { WineSuiteParametersService } from '@services/dws/wine-suite-parameters.service';
import { WineSuiteParameters } from '@interfaces/dws/wine-suite-parameters';
import { EventBusEvent, EventBusService } from './services/event-bus.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { not } from '@angular/compiler/src/output/output_ast';
import { datadogRum } from '@datadog/browser-rum';
import { da } from 'date-fns/locale';



declare let versionApp: any;

marker('Cellar settings');
marker('Transactional emails');
marker('Your Google Calendar account has been successfully associated to the winery!')
marker('There was an error associating your Google Calendar account to the winery. Try again later.')
marker('Your Outlook Calendar account has been successfully associated to the winery!')
marker('There was an error associating your Outlook Calendar account to the winery. Try again later.')
marker('Link Outlook Calendar')
marker('Gifts')

function launchHelp() {
  window.document.getElementById('help-button')?.click();
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterContentInit {
  title = 'winery-saas';
  account$: Observable<Account | null>;
  selfAccount$: Observable<SelfAccount | null>;
  adminAuth$: Observable<Authorization | null>;
  notifications$: Observable<Notification[] | null> = of(null);
  messages$: Observable<Notification[] | null> = of(null);
  newNotification = 0;
  newMessages = 0;
  openCrm: boolean = false;
  openMarketing: boolean = false;
  openSales: boolean = false;
  openManagement: boolean = false;
  openExperiencesAndProducts: boolean = false;
  version = versionApp;
  versionPostfix: string = environment.values['version_postfix'];
  versionRibbon: string = environment.values['version_ribbon'];
  dwsPro: boolean = false;
  adminModule = false;
  wineryId!: string;
  adminAsWinery: boolean = false
  haveBanner: boolean = false;
  bannerText: string = '';
  showBanner: boolean = false;
  showBannerAdmin: boolean = false;
  onlyUnreadedNotification: boolean = false;
  onlyUnreadedMessages: boolean = false;
  pageNotification: number = 1;
  pageMessages: number = 1;

  get isLoading(): boolean {
    return LoadingComponent.loading;
  };

  constructor(translate: TranslateService,
    public router: Router,
    private accounts: AccountsService,
    private notifications: NotificationService,
    private reservationDetailService: ReservationDetailService,
    private reservationService: ReservationsService,
    private dialog: MatDialog,
    private googleAuthService: GoogleAuthService,
    private hostCompanyService: HostCompanyService,
    private cd: ChangeDetectorRef,
    private authenticationService: AuthenticateAdminService,
    private outlookAuthService: OutlookAuthService,
    private preorderService: PreordersService,
    private eventBusService: EventBusService,
    private wineSuiteParametersService: WineSuiteParametersService
  ) {
    translate.setDefaultLang('it');
    const l = locale();
    if (l.stored != l.locale) location.reload();
    translate.use(l.locale);
    this.account$ = this.accounts.account$.asObservable();
    this.selfAccount$ = this.accounts.selfAccount$.asObservable();

    this.accounts.company$.subscribe(company => {
      if (company) {
        this.dwsPro = company.dws_pro_enabled;
        this.wineryId = company.id;
      }
    });

    this.notifications$ = this.notifications.notification$.asObservable();
    this.notifications.totalUnreadedNotifications$.subscribe((response) => {
      this.newNotification = response! || 0;
    });
    //this.notifications$.subscribe((response) => {
    //  this.newNotification = response!.reduce((accumulator, notification) => {
    //    if (!notification.viewed_at) return accumulator + 1;
    //    return accumulator;
    //  }, 0);
    //});

    this.messages$ = this.notifications.messages$.asObservable();
    this.notifications.totalUnreadedMessages$.subscribe((response) => {
      this.newMessages = response! || 0;
    });
    this.messages$.subscribe((response) => {
      for (const message of response!) {
        if (!!!!message.payload && (!!!message.payload.due_date || (!!!!message.payload.due_date && new Date(message.payload.due_date) > new Date()))) {
          if (message.payload.type === 'banner' && this.haveBanner === false) {
            this.bannerText = message.title + " - " + message.message;
            this.haveBanner = true;
            this.showBanner = true;
          } else if (message.payload.type === 'messageBlue') {
            if (!!!message.viewed_at) {
              this.sendMessageWSBlue(message);
            }
          }
        }
      }
      //this.newMessages = response!.reduce((accumulator, notification) => {
      //  if (!notification.viewed_at) return accumulator + 1;
      //  return accumulator;
      //}, 0);
    });

    this.adminAuth$ = this.authenticationService.authorization$.asObservable();
    this.authenticationService.authorization$
      .subscribe((auth) => {
        this.adminModule = !!auth;
      });
    this.accounts.authorization$.subscribe((auth: any) => {
      this.adminAsWinery = auth?.is_admin && auth?.is_winery
    });
    (window as any)['__feature'] = feature;

    try {
      if(environment.name == 'production') {
        accounts.account$.subscribe(account => {
          if (account) {
            var nn = account as any;
            datadogRum.setUser( {id: nn.company?.id, email: account.email} );
            datadogRum.init({
              applicationId: 'ccb437e3-ba8d-4fb5-99c4-de4d9bfe9683',
              clientToken: 'pub34f8092272592d199216e4f1992f742f',
              // `site` refers to the Datadog site parameter of your organization
              // see https://docs.datadoghq.com/getting_started/site/
              site: 'datadoghq.eu',
              service: 'dws',
              env: environment.name,
              // Specify a version number to identify the deployed version of your application in Datadog
              // version: '1.0.0',
              sessionSampleRate: 100,
              sessionReplaySampleRate: 20,
              trackUserInteractions: true,
              trackResources: true,
              trackLongTasks: true,
              defaultPrivacyLevel: 'mask-user-input',
          });
            datadogRum.startSessionReplayRecording();
          }
        });
        }
    } catch (e) {
      console.error(e);
    }
  }

  ngAfterContentInit() {
    setTimeout(() => {
      observe(this.hostCompanyService.dwsProEnabled()).subscribe(enabled => {
        this.dwsPro = enabled;
        this.cd.detectChanges();
      })
      this.refreshNotifications();
      this.refreshNotificationsMex();
    }, 500);
    setTimeout(() => {
      this.wineSuiteParametersService.showWineSuiteParameters().subscribe(x => {
        if (!!!!x.bannerContent) {
          this.bannerText = x.bannerContent;
          this.haveBanner = true;
          this.showBanner = true;
        }
      });
    }, 2500);

  }

  async logoutClicked() {
    this.accounts.logout();
    await this.router.navigate(['/login']);
  }

  async logoutAdminClicked() {
    this.authenticationService.logout();
    await this.router.navigate(['/admin/login']);
  }

  openNotification(notification: Notification) {
    var notificationId = notification.id;
    this.notificationDismiss(notificationId!);
    if (this.notificationIsReservation(notification)) {
      LoadingComponent.loading = true;
      combineLatest([
        this.notifications.get(notification.id!)
      ]).subscribe(([_]) => {
        this.dialog.open(ReservationComponent, {
          minWidth: 1031,
          data: {
            reservationId: notification.payload.personalizations[0].dynamic_template_data.reservation.id,
          }
        });
      }).add(() => LoadingComponent.loading = false);
    } else if (this.notificationIsSelfOrder(notification)) {
      const selfOrder = notification?.payload?.personalizations[0]?.dynamic_template_data?.self_order;
      this.preorderService.get(selfOrder.id)
        .subscribe(async _ => {
          const tree = this.router.createUrlTree(['/pos/self-order/', selfOrder.id], { queryParams: { channelId: selfOrder.channel_id } });
          const url = this.router.serializeUrl(tree);
          await window.open(url, '_blank');
        }, _ => toast(tr('This preorder has been promoted or does not exist'))
        )
    }
  }

  openMessage(notification: Notification) {
    var payload = notification.payload;
    var type = payload.type;
    this.notificationDismiss(notification.id!);
    var show_notification = payload.show_notification;
    if (type === 'banner' || type === 'toast') {
      toast(notification.title + " - " + notification.message, 5000, "bottom", tr("Close"));
    } else if (type === 'messageBlue') {
      this.sendMessageWSBlue(notification);
    }

  }

  hideBanner(event: MouseEvent) {
    var type = event.type;
    if (type == "mouseover") {
      this.showBanner = false;
    } else if (type == "mouseleave") {
      setTimeout(() => {
        this.showBanner = true;
      }, 2000);
    }
  }

  hideBannerAdmin(event: MouseEvent) {
    var type = event.type;
    if (type == "mouseover") {
      this.showBannerAdmin = false;
    } else if (type == "mouseleave") {
      setTimeout(() => {
        this.showBannerAdmin = true;
      }, 2000);
    }
  }

  async openReportsDashboard() {
    await this.router.navigate(['/reports-dashboard']);
  }

  async openDashboard() {
    await this.router.navigate(['/dashboard']);
  }

  async openAvailability() {
    await this.router.navigate(['/availability']);
  }

  async openExperiences() {
    await this.router.navigate(['/experiences']);
  }

  async openCompanyManagement() {
    await this.router.navigate(['/company-management']);
  }

  async openTransactionalEmails() {
    await this.router.navigate(['/transactional-emails']);
  }

  async gotTo(route: string) {
    await this.router.navigate([route]);
  }

  openManual() {
    window.open(environment.values['manual-url'], '_blank');
  }

  async openContacts() {
    await this.router.navigate(['/contacts']);
  }

  async openSegments() {
    await this.router.navigate(['/segments']);
  }

  async openImportContact() {
    await this.router.navigate(['/winery-contact-import']);
  }

  async openTags() {
    await this.router.navigate(['/tags']);
  }

  async openProducts() {
    await this.router.navigate(['/products']);
  }

  async openProductCategory() {
    await this.router.navigate(['/product-categories']);
  }

  async openMarketingTab(tab: string) {
    await this.router.navigate(['/marketing', tab]);
    this.openMarketing = !this.openMarketing;
  }

  restartState() {
    this.openCrm = false;
    this.openSales = false;
    this.openMarketing = false;
    this.openManagement = false;
    this.openExperiencesAndProducts = false;
  }

  restartStateCrm() {
    this.openSales = false;
    this.openMarketing = false;
    this.openManagement = false;
    this.openExperiencesAndProducts = false;
    if (this.openCrm) {
      this.openCrm = true;
    }
  }

  restartStateSales() {
    this.openCrm = false;
    this.openMarketing = false;
    this.openManagement = false;
    this.openExperiencesAndProducts = false;
    if (this.openSales) {
      this.openSales = true;
    }
  }

  restartStateMarketing() {
    this.openCrm = false;
    this.openSales = false;
    this.openManagement = false;
    this.openExperiencesAndProducts = false;
    if (this.openMarketing) {
      this.openMarketing = true;
    }
  }

  restartStateManagement() {
    this.openCrm = false;
    this.openSales = false;
    this.openMarketing = false;
    this.openExperiencesAndProducts = false;
    if (this.openManagement) {
      this.openManagement = true;
    }
  }

  restartStateExperiencesAndProducts() {
    this.openCrm = false;
    this.openSales = false;
    this.openMarketing = false;
    this.openManagement = false;
    if (this.openExperiencesAndProducts) {
      this.openExperiencesAndProducts = true;
    }
  }
  refreshNotifications() {
    this.notifications.list(this.pageNotification, 10, true, this.onlyUnreadedNotification == false).toPromise();
  }

  refreshNotificationsMex() {
    this.notifications.listMexs(this.pageMessages, 10, true, this.onlyUnreadedMessages == false).toPromise();
  }

  async notificationsAllDismiss() {
    this
      .notifications
      .markAllAsViewed()
      .subscribe(() => this.refreshNotifications())
  }

  async messagesAllDismiss() {
    this
      .notifications
      .markAllAsViewedMex()
      .subscribe(() => this.refreshNotificationsMex())
  }



  notificationsClosed() {
    // DO NOTHING
  }

  messaggesClosed() {
    // DO NOTHING
  }

  async notificationDismiss(notificationId: string) {
    this
      .notifications.markAsViewed(notificationId)
      .subscribe(() => {
        this.refreshNotifications();
        this.refreshNotificationsMex();
      })
  }


  async discontCodeClicked() {
    await this.router.navigate(['/discount']);
  }

  async giftClicked() {
    await this.router.navigate(['/gifts']);
  }

  async reservationsClicked() {
    await this.router.navigate(['/reservations']);
  }

  async googleCalendarLinkClicked() {
    const credentials = await this.googleAuthService.authorize().toPromise();
    if (credentials.error == "popup_closed_by_user") return;
    LoadingComponent.loading = true;
    this.googleAuthService.link(credentials, this.wineryId).subscribe(
      (result) => {
        toast(tr('Your Google Calendar account has been successfully associated to the winery!'))
        console.log('GoogleCalendar: ', result)
      },
      (err) => {
        toast(tr('There was an error associating your Google Calendar account to the winery. Try again later.'))
        console.log('error', err)
      }
    ).add(() => LoadingComponent.loading = false);
  }

  get isPublicRoute() {
    return this.router.url === '/login' || this.router.url.startsWith('/registration') || this.router.url.startsWith('/login') ||
      this.router.url.startsWith('/unsubscribe') || this.router.url.startsWith('/reset-password');
  }

  async outlookCalendarLinkClicked() {
    let closedWindow = null;
    LoadingComponent.loading = true;
    const credentials = await this.outlookAuthService.authorize()
      .catch((error) => {
        closedWindow = error;
        LoadingComponent.loading = false;
      })
    LoadingComponent.loading = false;
    if (closedWindow) return;

    observe(this.outlookAuthService.link(credentials, this.wineryId)).subscribe(
      (result) => {
        toast(tr('Your Outlook Calendar account has been successfully associated to the winery!'))
        console.log('OutlookCalendar: ', result)
      },
      (error) => {
        toast(tr('There was an error associating your Outlook Calendar account to the winery. Try again later.'))
        console.log('error', error)
      }
    )
  }

  notificationIsReservation(notification: Notification): boolean {
    return notification?.payload && notification?.payload?.personalizations && notification?.payload?.personalizations[0]?.dynamic_template_data?.reservation !== undefined
  }

  notificationIsSelfOrder(notification: Notification): boolean {
    return notification?.payload && notification?.payload?.personalizations && notification?.payload?.personalizations[0]?.dynamic_template_data?.self_order !== undefined
  }


  notificationIsNews(notification: Notification): boolean {
    return notification?.trigger != undefined && notification?.trigger == 'news_channel'
  }

  bannerAdminAcessText(): string {
    let companyName = '';
    this.accounts.company$.subscribe(company => companyName = company?.name || '');
    return `${tr('You are accessing as')} ${companyName}`
  }

  showBannerText(): string {
    return this.bannerText;
  }


  formatDate(date: string | Date | undefined) {
    if (!date) return null;
    return moment(date).toISOString();

  }

  openHelp() {
    // OPEN THE WIDGET FORM
    eval("document.querySelector(\"iframe[name='JSD widget']\").contentDocument.getElementById('help-button').click()");
  }

  onNotificationVisualizationClick(event: MouseEvent) {
    event.stopPropagation();
  }

  onChangeVisualization(event: MatButtonToggleChange, type: string): void {
    var value = event.source.value;
    var isAll = value === 'all';
    if (type === 'notification') {
      this.onlyUnreadedNotification = !isAll;
      this.refreshNotifications();
    } else {
      this.onlyUnreadedMessages = !isAll;
      this.refreshNotificationsMex();
    }
  }

  onScrollDownNotification() {

  }

  onScrollDownMessages() {

  }

  sendMessageWSBlue(message: Notification) {
    if (!message) return;
    var event: EventBusEvent = {
      id: message.id!,
      name: message.trigger!,
      data: message
    }
    this.eventBusService.publish(event, message.trigger!);
  }

  messagesLoadMore(event: MouseEvent) {
    event.stopPropagation();
    this.pageMessages++;
    this.refreshNotificationsMex();
  }

  notificationsLoadMore(event: MouseEvent) {
    event.stopPropagation();
    this.pageNotification++;
    this.refreshNotifications();
  }

}