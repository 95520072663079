import { Injectable } from '@angular/core';
import { DWS } from '@interfaces/dws';
import { TRIGGER_EMAIL } from '@interfaces/dws/transactional-email';

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { route as r } from '@util/route';
import { observe } from '@web/util/loading/loading';
import { TransactionalEmailsService } from '../transactional-emails.service';
import { AccountsService } from '@services/accounts.service';
import { from } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class TransactionalEmailsResolverService implements Resolve<DWS.TransactionalEmailReduced[]>{
  constructor(private service: TransactionalEmailsService, private accounts: AccountsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DWS.TransactionalEmailReduced[]> {
    const transactionalEmailType = r.access(route).param('transactionalEmailType');
    let trigger: string | undefined;
    if (transactionalEmailType) trigger = TRIGGER_EMAIL.find(e => transactionalEmailType.includes(e));
    const args$ = from(this.accounts.getLogin()).pipe(map(login => login!.company!.id));
    const obs$ = args$.pipe(first(), switchMap(args => this.service.listFilterByTrigger(trigger, args)));
    return observe(obs$);
  }





}


