import { Component, OnInit } from '@angular/core';
import {
  ExportDataExperienceResponse,
  ExportDataForExperienceResponse,
  ExportDataForExperienceService
} from "@services/dws/export-data-for-experience";
import { observe } from '@web/util/loading/loading';
import { access } from "@util/route/access";
import { ActivatedRoute } from "@angular/router";
import { Login } from "@services/accounts.service";
import { tr } from "@util/tr";
import { CompanyService } from '@services/company.service';

@Component({
  selector: 'reservation-pdf-exporter',
  templateUrl: './reservation-pdf-exporter.component.html',
  styleUrls: ['./reservation-pdf-exporter.component.scss'],
})
export class ReservationPdfExporterComponent implements OnInit {

  login: Login;
  company: { id: string, name: string, avatar: string };
  exportingPdf = false;
  dataPdf: ExportDataExperienceResponse | undefined = undefined;
  cssFilePath: string = '/assets/styles/pdfExportStyle.css';


  constructor(private exportDataForExperienceService: ExportDataForExperienceService, private route: ActivatedRoute, private companyService: CompanyService) {
    this.login = access(route).data('login');
    this.company = access(route).data('companyAlt');
 
  }

  ngOnInit() { }

  public downloadAsPDF(startDate: Date, endDate: Date, experienceId: string | undefined, wineryId: string): void {

    var that = this;
    function doThing(){
      observe(that.exportDataForExperienceService.fetch({
        startDate: startDate,
        endDate: endDate,
        experienceId: experienceId,
        wineryId: wineryId
      })).subscribe(e => {
        that.dataPdf = e;
        that.exportingPdf = true;
  
        const interval = setInterval(() => {
          const element = <HTMLElement>document.getElementById("pdfExportExperiences");
  
          if (element) {
            if (that.exportingPdf) {
              that.print(element)
              that.exportingPdf = false;
            }
          }
  
          clearInterval(interval);
          return;
        }, 300);
      });
    }

    if(!this.company){
      observe(this.companyService.get(wineryId), false).subscribe(data => {
        this.company = data;
        doThing();
      });
    } else {
      doThing();
    }
    
  }

  public print(element: HTMLElement): void {
    function getElementTag(tag: keyof HTMLElementTagNameMap): string {
      const html: string[] = [];
      const elements = document.getElementsByTagName(tag);
      for (let index = 0; index < elements.length; index++) {
        html.push(elements[index].outerHTML);
      }
      return html.join('\r\n');
    }

    let printContents, styles = '', links = '';
    const baseTag = getElementTag('base');

    printContents = element.innerHTML;
    const popupWin = window.open("availability", "_blank", "top=0,left=0,height=auto,width=auto");
    popupWin?.document.open();
    popupWin?.document.write(`
        <html>
          <head>
            <title>${tr('Reservations')}</title>
            ${baseTag}         
            <link href="https://fonts.googleapis.com/css?family=Poppins" rel="stylesheet">
            <link href="/assets/styles/pdfExportStyle.css" rel="stylesheet">
            <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet">

          </head>
          <body>
            ${printContents}
            <script defer>
              function triggerPrint(event) {
                var windowUrl = 'Availability';
                window.removeEventListener('load', triggerPrint, false);
                setTimeout(function() {
                  closeWindow(window.print());
                }, ${0});
              }
              function closeWindow(){
                  window.close();
              }
              window.addEventListener('load', triggerPrint, false);
            </script>
          </body>
        </html>`);
    popupWin?.document.close();
  }

  public labelTotal(x: ExportDataForExperienceResponse) {
    const labels = x.summary.labelsTotal;
    // @ts-ignore
    return Object.keys(labels).map(e => +labels[e]).reduce((p, a) => p + a, 0)
  }

  public labelsAndExtrasJoinSummaryToObjArr(x: ExportDataForExperienceResponse) {
    const labels = x.summary.labelsTotal;
    const extras = x.summary.extrasTotal;
    const l = Object.keys(labels).map(e => { return { labelName: e, labelValue: labels[e] }; });
    const e = Object.keys(extras).map(e => { return { labelName: e, labelValue: extras[e] }; });

    return [...l, ...e]
  }

  public labelsAndExtrasToObjArr(r: { labels: Record<string, number>; extras: Record<string, number> }) {
    const labels = r.labels;
    const extras = r.extras;
    const l = Object.keys(labels).map(e => { return { labelName: e, labelValue: labels[e] }; });
    const e = Object.keys(extras).map(e => { return { labelName: e, labelValue: extras[e] }; });

    return [...l, ...e]

  }

  getOrigin(origin: any): any {
    if(!!!origin){
      return "";
    }
    var originFormatted = "" + origin?.replace("https://", "").replace("http://", "").replace("www.", "").replace(/\:\d+/g, "").toLowerCase();
    if (("" + origin).toLocaleLowerCase().indexOf("manual") > -1 || "Wine Suite".indexOf(origin) > -1) {
      originFormatted = tr("Manual Reservation");
    }
    originFormatted = tr(originFormatted);
    return originFormatted ? originFormatted : ( origin ? tr(origin) : "" );
  }
}