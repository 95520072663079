import { CampaignEditor } from 'interfaces/dws/campaign-editor';
import { Winery } from './winery';
import { MatTableDataSource } from '@angular/material/table';
import { DWS } from 'interfaces/dws/index';

export namespace TransactionalEmail {
  export interface Data {
    id?: number;
    subject: string;
    type: string;
    liveTemplateData: CampaignEditor;
    draftTemplateData?: CampaignEditor;
    winery?: Winery;
  }

  export interface ReducedData {
    id?: number;
    subject: string;
    type: string;
    winery?: Winery;
    isDraft: boolean;
    isInactive: boolean;
    activationHasBeenChanged: boolean;
    duplicateSketch: boolean;
  }

  export interface NewBase {
    subject: string;
    type: string;
    liveTemplateData: CampaignEditor;
    draftTemplateData?: CampaignEditor;
    winery?: Winery
  }
  export interface NewWinery extends NewBase {
    winery?: Winery
  }

  export interface TestEmail {
    recipient: string;
    subject: string;
    templateTest: CampaignEditor;
  }
}

export type TransactionalEmail = TransactionalEmail.Data;
export type TransactionalEmailReduced = TransactionalEmail.ReducedData;
export type TestTransactionalEmail = TransactionalEmail.TestEmail;

export namespace Category {
  export interface Data {
    name: string;
    trigger: string;
    transactionalEmails: TransactionalEmailReduced[];
    dataSource: MatTableDataSource<DWS.TransactionalEmail.ReducedData & { checked?: boolean }>
  }
  
  export const make = (trigger: TriggerType): Category & { checked?: boolean } => {
    return {
      name: TRIGGER_NAMES[trigger],
      trigger,
      transactionalEmails: [],
      dataSource: new MatTableDataSource<DWS.TransactionalEmail.ReducedData>([]),
      
      // @ts-ignore
      get checked(): boolean {
        return this.dataSource.data.every(te => !te.winery || te.checked);
      },
      
      // @ts-ignore
      set checked(v: boolean) {
        this.dataSource.data.forEach(te => te.checked = v);
      }
    }
  }
}

export type Category = Category.Data;

type TriggerType =  'create-to' | 'confirm-to' |  'reject-to' |  'revoke-to' |  'reminder-to' |  'feedback-to' |  'gift-to' | 'assigned-to' | 'self-order-request' | 'order-receipt';

export const TRIGGER_EMAIL: TriggerType[] = [
  'create-to',
  'confirm-to',
  'reject-to',
  'revoke-to',
  'reminder-to',
  'feedback-to',
  'gift-to',
  'assigned-to',
];

export const ORDER_TRIGGER_EMAIL: TriggerType[] = [
  'self-order-request',
  'order-receipt',
];

export const TRIGGER_NAMES = {
  'create-to': 'Reservation request',
  'confirm-to': 'Booking confirmation',
  'reject-to': 'Reservation rejection',
  'revoke-to': 'Revocation of reservation',
  'reminder-to': 'Reservation reminder',
  'feedback-to': 'Booking feedback',
  'gift-to': 'Buy as a gift',
  'assigned-to': 'Notification of reservation to employee',
  'self-order-request': 'Self order request',
  'order-receipt': 'Order receipt',
}
