import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, throwError } from 'rxjs';
import { Authorization } from '@interfaces/authorization';
import { resource } from '@util/resource';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Account, AccountRegistration } from '@interfaces/account';
import { storage } from '@util/storage';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';
import { Host } from '@interfaces/host';
import { EditSelfAccount, SelfAccount } from '../interfaces/dws/self-account';

import { Base64 } from '@util/base64'

export interface Login {
  account: Account;
  authorization: Authorization;
  company: Host.Company;
  selfAccount: SelfAccount;
}
interface LoginResponseDWS {
  idToken: string,
  wineryId: string
}

interface LoginResponse {
  account: Account;
  expires_at: string;
  host_data: any;
}

marker([
  'Invalid credentials',
  'Unknown error'
])

@Injectable({
  providedIn: 'root'
})
export class AccountsService {
  login$: ReplaySubject<Login | null> = new ReplaySubject<Login | null>(1)
  authorization$: ReplaySubject<Authorization | null> = new ReplaySubject<Authorization | null>(1);
  account$: ReplaySubject<Account | null> = new ReplaySubject<Account | null>(1);
  company$: ReplaySubject<Host.Company | null> = new ReplaySubject<Host.Company | null>(1);
  selfAccount$: ReplaySubject<SelfAccount | null> = new ReplaySubject<SelfAccount | null>(1);

  constructor() {
    storage.get('login').then(l => this.login$.next(l));
    this.login$.subscribe(async l => {
      await storage.set('login', l);
      await storage.set('is_admin', !!l?.authorization?.is_admin);
      await storage.set('is_winery', !!l?.authorization?.is_winery);
      this.authorization$.next(l ? l.authorization : null);
      this.account$.next(l ? l.account : null);
      this.company$.next(l ? l.company : null);
      this.selfAccount$.next(l ? l.selfAccount : null);
    })
  }

  logout() {
    this.login$.next(null);
  }

  login(email: string, password: string): Observable<Login> {
    const base64password = Base64.encode(password);
    return resource('crm://account/authenticate')
        .post<LoginResponseDWS>({ email, base64password }, { observe: 'response' })
        .pipe(
            map(response => {
              if (!response.body) throw({
                type: response.type,
                message: 'Unknown error',
                cause: response,
                status: response.status
              })
              return {
                authorization: {
                  expires_at: '',
                  token: response.headers.get('Authorization') as string,
                  winery_id: response.body.wineryId,
                  is_admin: false,
                  is_winery: true
                },
              }
            }),

            mergeMap(data => {
              return resource('v2://host')
                  .authorization('bearer', data.authorization.token.replace('Bearer ', ''))
                  .get<any>()
                  .pipe(
                      map(hostData => {
                        let company = {...hostData.company};
                        company.id = data.authorization.winery_id
                        return { ...data, company, account: {...hostData}, }
                      })
                  );
            }),
            mergeMap(data => {
              return resource('crm://self/account')
                  .authorization('bearer', data.authorization.token.replace('Bearer ', ''))
                  .get<SelfAccount>()
                  .pipe(
                      map(accountData => {
                        return { ...data, selfAccount: { ...accountData }}
                      })
                  );
            }),
            mergeMap(data => {
              const syncTags = resource('crm://tags/winery/wineryId')
                  .params(
                      { wineryId: data.company.id }
                  )
                  .authorization('bearer', data.authorization.token.replace('Bearer ', ''))
                  .get<any>();
              const syncAudiences = resource('crm://audiences/winery/wineryId')
                  .params(
                      { wineryId: data.company.id }
                  )
                  .authorization('bearer', data.authorization.token.replace('Bearer ', ''))
                  .get<any>();
              return forkJoin([syncTags, syncAudiences])
                  .pipe(
                      map(() => {
                        return data
                      })
                  );
            }),
            catchError(e => {
              if (e instanceof HttpErrorResponse) {
                if (e.status === 401) {
                  return throwError({
                    type: 'unauthorized',
                    message: 'Invalid credentials',
                    cause: e,
                    status: e.status
                  });
                }
                if (e.status === 400) {
                  return throwError({
                    type: 'notmigrate',
                    message: 'Account need migration',
                    cause: e,
                    status: e.status
                  });
                }
              }
              return throwError({
                type: 'unknown',
                message: 'Unknown error',
                cause: e,
                status: e.status
              });
            })
        )
  }

  async use(login: Login): Promise<void> {
    this.login$.next(login);
    await new Promise(r => setTimeout(r, 40));
  }

  async getLogin(): Promise<Login | null> {
    return storage.get('login');
  }

  async getRole(): Promise<boolean | null> {
    return storage.get('role');
  }

  recover(email: string): Observable<unknown> {
    return resource('v2://password')
        .post({
          account: {
            email
          }
        })
        .pipe(
            catchError((e: HttpErrorResponse) => {
              if (e.status === 422) {
                return throwError({
                  type: 'unauthorized',
                  message: 'Account not found',
                  cause: e
                })
              }
              return throwError({
                type: 'unknown',
                message: 'Unknown error',
                cause: e
              });
            })
        )
  }

  update(account: AccountRegistration): Observable<unknown> {
    return resource('v2://registrations')
        .put({
          account: account
        });
  }

  edit(editAccount: EditSelfAccount): Observable<{ token: string }> {
    return resource('crm://self/account/edit')
        .post(editAccount)
  }
}
