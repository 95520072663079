import { Winery } from 'interfaces/dws/winery';
import { DWS } from 'interfaces/dws/index';
import { TranslateDomainPipe } from 'projects/web/src/app/pipes/translate/translate-domain.pipe';
import { tr } from 'util/tr';
import { locale } from 'util/locale';
import { CurrencyPipe, TitleCasePipe } from '@angular/common';
import * as moment from 'moment';
import { Audience } from "./audience";
import { WineryWineries } from './winery-wineries';
import { Host } from '@interfaces/host';

export type ConditionType = 'all' | 'one' | 'none';

export namespace Matcher {
  import AudienceValue = Audience.AudienceValue;

  export interface Operator {
    id: number;
    nameEn: string;
    nameIt: string;
    value: string;
  }

  export type FormatValue = {
    matcher: ContactMatcher;
    v: ContactMatcherValue.Data;
    countries: DWS.Country[];
    segments: DWS.Segment[];
    languages?: DWS.Language[];
    products?: DWS.Product[];
    tags: DWS.TagCrm[];
    wineryChannels: DWS.WineryChannel[];
    forms: DWS.Form[];
    wineryWineries: WineryWineries.WineryDetail[];
    experiences: Host.Experience[];
    origins: DWS.Origin[];
    wineryOrigins: {id: string, name: string}[];
  }

  export interface FilterContactMatcherValue {
    conditionType: ConditionType;
    winery: Winery;
    values: ContactMatcherValue[] | AudienceValue[];
  }

  export namespace ContactMatcherValue {
    export interface Data {
      value: number;
      stringValue?: string;
      contactMatcherOperator: ContactMatcherOperator;
    }

    export function formatValue(formatValue: FormatValue): any {
      const {matcher, v, countries, segments, languages, products, tags, forms, wineryChannels, wineryWineries, experiences, origins, wineryOrigins} = formatValue
      if (!matcher) return v.value;
      const countriesMap = countries.reduce((data: any, c: DWS.Country) => {
        data[c.id] = c;
        return data;
      }, {} as any);
      const segmentMap = segments.reduce((data: any, s: DWS.Segment) => {
        data[s.id] = s;
        return data;
      }, {} as any);
      const tagMap = tags.reduce((data: any, s: DWS.TagCrm) => {
        data[s.id] = s;
        return data;
      }, {} as any);
      if (ContactMatcher.isCountryValue(matcher.id)) {
        return TranslateDomainPipe.transform(countriesMap[v.value]);
      }
      if (ContactMatcher.isLanguageValue(matcher.id) && languages) {
        const languagensMap = languages.reduce((data: any, l: DWS.Language) => {
          data[l.id] = l;
          return data;
        }, {} as any);
        return TranslateDomainPipe.transform(languagensMap[v.value]);
      }
      if (ContactMatcher.isSpecificProductPurchased(matcher.id) && products) {
        const productsMap = products.reduce((data: any, p: DWS.Product) => {
          data[p.id] = p;
          return data;
        }, {} as any);
        return TranslateDomainPipe.transform(productsMap[v.value]);
      }
      if (ContactMatcher.isSegmentValue(matcher.id)) {
        const segment = segmentMap[v.value];
        if (segment) return segment.name;
      }
      if (ContactMatcher.isClientTypeValue(matcher.id)) {
        switch (v.value) {
          case 0:
            return tr('Private');
          case 1:
            return tr('Business');
          case 2:
            return tr('Company');
        }
      }
      if (ContactMatcher.isPrivacyValue(matcher.id)) {
        switch (v.value) {
          case 1:
            return tr('Privacy & Terms');
          case 2:
            return tr('Marketing purposes');
          case 3:
            return tr('Profiling');
          case 4:
            return tr('Manual marketing consent');
        }
      }
      if (ContactMatcher.isMonthValue(matcher.id)) {
        const month = new Date(2020, v.value - 1, 1).toLocaleDateString(locale().locale, {month: 'long'});
        return new TitleCasePipe().transform(month);
      }
      if (ContactMatcher.isDateValue(matcher.id)) {
        return new Date(moment.unix(v.value).toDate()).toLocaleDateString();
      }
      if (ContactMatcher.isCurrencyValue(matcher.id)) {
        return new CurrencyPipe(locale().locale, 'EUR').transform(v.value / 100, 'EUR', 'symbol');
      }
      if (ContactMatcher.isTagValue(matcher.id)) {
        const tag = tagMap[v.value];
        if (tag) return tag.name;
      }
      if (ContactMatcher.isFormAsPresence(matcher.id)) {
        const form = forms?.find(f => f.id === v.stringValue);
        if (form) return form.formName;
      }
      if (ContactMatcher.isPurchaseFromChannel(matcher.id)) {
        const channel = wineryChannels.find(c => c.id === v.stringValue);
        if (channel) return channel.name;
      }
      if (ContactMatcher.isAssociatedWinery(matcher.id)) {
        const winery = wineryWineries.find(c => c.id === v.stringValue);
        if (winery) return winery.name;
      }
      if (ContactMatcher.isAttendedExperience(matcher.id)) {
        const experience = experiences.find(e => e.id === v.stringValue);
        if (experience) return experience.title;
      }
      if (ContactMatcher.isContactOrigin(matcher.id)) {
        let origin:any = origins.find(o => o.id === v.value);
        if (origin) return TranslateDomainPipe.transform(origin);
        origin = wineryOrigins.find(o => o.id === v.stringValue);
        if (origin) return origin.name;
      }
      return v.value;
    }

  }

  export type ContactMatcherValue = ContactMatcherValue.Data;


  export namespace ContactMatcher {
    export interface Data {
      id: number;
      nameEn: string;
      nameIt: string;
      contactMatcherOperators: ContactMatcherOperator[];
    }

    export function isCountryValue(contactMatcherId: number): boolean {
      const countryId = 4;
      return Number(contactMatcherId) === Number(countryId);
    }

    export function isSegmentValue(contactMatcherId: number): boolean {
      const countryId = 14;
      return Number(contactMatcherId) === Number(countryId);
    }

    export function isTagValue(contactMatcherId: number): boolean {
      const id = 17;
      return Number(contactMatcherId) === Number(id);
    }

    export function isPrivacyValue(contactMatcherId: number): boolean {
      const id = 18;
      return Number(contactMatcherId) === Number(id);
    }

    export function isLanguageValue(contactMatcherId: number): boolean {
      const countryId = 15;
      return Number(contactMatcherId) === Number(countryId);
    }

    export function isMonthValue(contactMatcherId: number): boolean {
      const numberMatcherId = [3];
      return numberMatcherId.some(n => n === Number(contactMatcherId));
    }

    export function isClientTypeValue(contactMatcherId: number): boolean {
      const clientTypeId = 9;
      return Number(contactMatcherId) === Number(clientTypeId);
    }

    export function isDateValue(contactMatcherId: number): boolean {
      const numberMatcherId = [2, 19];
      return numberMatcherId.some(n => n === Number(contactMatcherId));
    }

    export function isCurrencyValue(contactMatcherId: number): boolean {
      const id = 1;
      return Number(contactMatcherId) === Number(id);
    }

    export function isSpecificProductPurchased(contactMatcherId: number): boolean {
      const id = 22;
      return Number(contactMatcherId) === Number(id);
    }

    export function isQrCodeAsPresence(contactMatcherId: number): boolean {
      const id = 24;
      return Number(contactMatcherId) === Number(id);
    }

    export function isFormAsPresence(contactMatcherId: number): boolean {
      const id = 25;
      return Number(contactMatcherId) === Number(id);
    }

    export function isPurchaseFromChannel(contactMatcherId: number): boolean {
      const id = 26;
      return Number(contactMatcherId) === Number(id);
    }

    export function isAssociatedWinery(contactMatcherId: number): boolean {
      const id = 27;
      return Number(contactMatcherId) === Number(id);
    }

    export function isAttendedExperience(contactMatcherId: number): boolean {
      const id = 28;
      return Number(contactMatcherId) === Number(id);
    }

    export function isDateRangeValue(contactMatcherId: number): boolean {
      const numberMatcherId = [29];
      return numberMatcherId.some(n => n === Number(contactMatcherId));
    }

    export function isContactOrigin(contactMatcherId: number): boolean {
      const id = 30;
      return Number(contactMatcherId) === Number(id);
    }

    export function isGenericStringValue(contactMatcherId: number): boolean {
      const numberMatcherId = [20, 21];
      return numberMatcherId.some(n => n === Number(contactMatcherId));
    }

    export function isGenericValue(contactMatcherId: number): boolean {
      return !isDateValue(contactMatcherId) && !isClientTypeValue(contactMatcherId) &&
        !isMonthValue(contactMatcherId) && !isCountryValue(contactMatcherId) && !isCurrencyValue(contactMatcherId) &&
        !isSegmentValue(contactMatcherId) && !isLanguageValue(contactMatcherId) && !isTagValue(contactMatcherId) &&
          !isPrivacyValue(contactMatcherId) && !isGenericStringValue(contactMatcherId) &&
          !isSpecificProductPurchased(contactMatcherId) && !isQrCodeAsPresence(contactMatcherId) && !isPurchaseFromChannel(contactMatcherId) &&
          !isFormAsPresence(contactMatcherId) && !isAssociatedWinery(contactMatcherId) &&
          !isAttendedExperience(contactMatcherId) && !isDateRangeValue(contactMatcherId) && !isContactOrigin(contactMatcherId);
    }
  }

  export type ContactMatcher = ContactMatcher.Data;

  export interface ContactMatcherOperator {
    id: number;
    operator: Operator;
    contactMatcherId: number;
    contactMatcher: ContactMatcher;
  }

  export interface ContactMatcherType {
    id: number;
    nameIt: string;
    nameEn: string;
  }
}