import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FlexModule } from '@angular/flex-layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxMaskModule } from 'ngx-mask';
import { DurationModule } from '@web/app/pipes/duration/duration.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { ConfirmModule } from '@web/app/components/dialogs/confirm';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { LoadingModule } from '@web/app/components/loading/loading.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ArrayPipeModule } from '@pipes/array/array.module';
import { FeatureToggleModule } from 'projects/web/src/app/modules/feature-toggle/feature-toggle.module';
import { TranslateDomainModule } from '@web/app/pipes/translate/translate-domain.module';
import { NewContactSidenavComponent } from 'projects/web/src/app/components/new-contact-sidenav/new-contact-sidenav.component';


@NgModule({
  declarations: [NewContactSidenavComponent],
  entryComponents: [NewContactSidenavComponent],
  exports: [
    NewContactSidenavComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSidenavModule,
    NgxMaskModule,
    TranslateModule,
    TranslateDomainModule,
    MatCheckboxModule,
    MatSelectModule
  ]
})
export class NewContactSidenavModule { }
