<div style="bottom: 8000px; position: fixed;font-family: 'Poppins'!important;">
  <div *ngIf="exportingPdf" id="pdfExportExperiences" class="contentFirstWrapper">
    <div class="contentDivWrapper">
      <div class="contentDivLogo">
          <img *ngIf="company && company.avatar" alt="LOGO" src="{{company.avatar}}"
               calendar_today style="width: 150px; position: relative; display: block; float: left; margin: 10px;">
      </div>
        <div class="contentDivTitleRes">
            <span class="summaryExperienceTitle">{{'Summary reservations' | translate}}</span>
      </div>
    </div>

    <div *ngFor="let sect of this.dataPdf!.results; let j = index;">
      <hr class="hrClass" />
        <div class="summaryExperienceNameDate" style="padding-top: 0px">

            <span class="material-icons">calendar_today</span>
            <span class="summaryDate">{{sect.summary.date | date:'dd/MM/yyyy' }}</span>
            <span class="material-icons" style="margin-right: -2px;">access_time</span>
          <span class="summaryDate">{{ " " }}{{sect.summary.time }}</span>
            <span class="material-icons">language</span>
            <span class="summaryContent">{{sect.summary.language}}</span>

        </div>
        <div class="summaryExperienceNameLine">
            <span class="summaryExperienceName">{{sect.summary.experienceName}}</span>
        </div>
        <div class="summarySublineNameLine">
            <div class="summaryParticipantsLine">
                <div>
          <span class="summaryLabel">{{'Reservations' | translate}}:</span>
          <span class="summaryContent">{{ sect.summary.totalParticipants}} </span>
                </div>
                <div>
          <span class="summaryLabel">{{'Total visitors' | translate}}:</span>
          <span class="summaryContent">{{ labelTotal(sect) }}{{ !!!!sect.summary.maxParticipants &&
            sect.summary.maxParticipants > 0 ? "/" + sect.summary.maxParticipants : "" }}</span>
                </div>
                <div>
          <span class="summaryLabel">{{'Total experiences' | translate}}:</span>
          <span class="summaryContent">{{sect.summary.sumNetTotalCents / 100}}€</span>
                </div>
                <div>
          <span class="summaryLabel">{{'Duration' | translate}}</span>
                    <span class="summaryContent">{{sect.summary.durationSeconds | duration}}</span></div>
        </div>

            <div class="summaryPriceLine">
                <div *ngFor="let l1 of labelsAndExtrasJoinSummaryToObjArr(sect); let aa = index;">
                    <div class="summaryPriceContent">
                        <span class="summaryLabel">{{l1.labelName}}:</span>
                        <span class="summaryContent">{{l1.labelValue}}</span>
                    </div>
                </div>
            </div>


        </div>
        <!-- <span class="reservationsTitle">{{ 'Reservations' | translate }}</span> -->
      <div class="singleReservation" *ngFor="let r of sect.reservations; let i = index;">
        <div>
            <div class="singleReservationFirstname">
            <span class="singleReservationTitleLine">{{r.firstName + " " + r.lastName}}</span>
            </div>
            <div class="singleReservationTitles">
                <span class="singleReservationsubTitleLine">{{r.email}}</span>
                <span class="singleReservationsubTitleLine">{{r.phone}}</span>
                <span class="singleReservationsubTitleLine">{{r.country}}</span>
          </div>
          <div class="singleReservationsubTitles">

          </div>
          <div style="display: flex; width: 100%;">
            <div style="width: 50%;">
              <div class="singleReservationDetails">
                  <span class="singleReservationLabel">{{'Reserved at' | translate }}:</span>
                  <span class="singleReservationContent">{{" "}}{{(r.reservationDate |
                  date:'dd/MM/yyyy')!}}</span>
              </div>

                <div class="singleReservationDetails">
                    <span class="singleReservationLabel">{{'Language' | translate}}:</span>
                    <span class="singleReservationContent">{{r.language}}</span>
                </div>

                <div class="singleReservationDetails">
                    <div *ngFor="let l2 of labelsAndExtrasToObjArr(r)">
                        <span class="singleReservationLabel">{{l2.labelName}}</span>
                        <span class="singleReservationContent">{{l2.labelValue}}</span>
                    </div>
                </div>
            </div>
              <div style="width: 50%;">
              <div class="singleReservationDetails">
                  <span class="singleReservationLabel">{{'Payment method' | translate}}:</span>
                  <span class="singleReservationContent">{{r.paymentMethodName}}</span>
              </div>
                  <div class="singleReservationDetails">
                      <span class="singleReservationLabel">{{'Origin' | translate }}:</span>
                      <span class="singleReservationContent">{{ getOrigin(r.origin) }}</span>
                  </div>
                  <div class="singleReservationDetails">
                      <span class="singleReservationLabel">{{'Discount' | translate}}:</span>
                      <span class="singleReservationContent">{{r.discountCents/100}} €</span>
                  </div>

              </div>
          </div>
            <div class="singleReservationFooter">

                <div style="margin-top: 7px;">
                    <span class="singleReservationFooterLabel">{{'Total' | translate}}:</span>
                    <span class="singleReservationFooterContent">{{r.netValueCents / 100}} €</span>
                </div>
                <div class="singleReservationFooterStatus">
                    <span class="singleReservationFooterContent">{{r.status | titlecase | translate}}</span>
                    <!--<span class="material-icon-completed material-icons" *ngIf="r.status === 'completed'; else rejectedIcon">check_circle</span>
                    <ng-template #rejectedIcon>
                      <span class="material-icon-rejected material-icons md-36">cancel</span>
                    </ng-template> -->

                    <img *ngIf="r.status === 'completed'" alt="completed"
                         class="statusImagesIconReservation" src="assets/icons/status/completed.png"/>
                    <img *ngIf="r.status === 'confirmed'" alt="confirmed"
                         class="statusImagesIconReservation" src="assets/icons/status/confirmed.png"/>
                    <img *ngIf="r.status === 'waiting'" alt="waiting"
                         class="statusImagesIconReservation" src="assets/icons/status/waiting.png"/>
                    <img *ngIf="r.status === 'revoked'" alt="revoked"
                         class="statusImagesIconReservation" src="assets/icons/status/revoked.png"/>
                    <img *ngIf="r.status === 'rejected'" alt="rejected"
                         class="statusImagesIconReservation" src="assets/icons/status/rejected.png"/>


                </div>
            </div>
        </div>
      </div>

    </div>
    <!-- FOOTER -->
      <hr class="hrClass"/>
    <div style="display: flex; flex-direction: row; justify-content: space-between; align-items: center;">
      <div style="padding-bottom: 10px; padding-left: 25px;">

      </div>
      <div style="padding-bottom: 10px;"></div>
        <div style="padding-bottom: 10px; padding-right: 25px; margin-top: 10px;">
        <img alt="" src="assets/images/logo-blue-horizontal.svg"
             style="width: 150px; position: relative; display: block; float: left; margin: 5px;">
      </div>
    </div>
  </div>
</div>