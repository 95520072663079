import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { resource } from '@util/resource';
import { DWS } from '@interfaces/dws';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TransactionalEmailsService {

  list(wineryId: string): Observable<DWS.TransactionalEmailReduced[]> {
    return resource('crm://transactional-emails')
      .params({ wineryId })
      .get<DWS.TransactionalEmail.ReducedData[]>();
  }

  listFilterByTrigger(trigger: string | undefined, wineryId: string): Observable<DWS.TransactionalEmailReduced[]> {
    if (!trigger) return this.list(wineryId);
    return this.list(wineryId).pipe(
      map(emails => emails.filter(email => email.type.includes(trigger)))
    );      
  }

  get(type: string, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/type')
      .params({ type, wineryId })
      .get<DWS.TransactionalEmail>();
  }

  saveLive(type: string, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/type')
      .params({ type, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }

  saveDraft(type: string, value: DWS.TransactionalEmail.NewBase, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/type/draft')
      .params({ type, wineryId })
      .post<DWS.TransactionalEmail>(value);
  }  

  restore(type: string, wineryId: string): Observable<void> {
    return resource('crm://transactional-emails/type')
      .params({ type, wineryId })
      .delete();
  }

  deleteDraft(type: string, wineryId: string): Observable<DWS.TransactionalEmail> {
    return resource('crm://transactional-emails/type/draft')
      .params({ type, wineryId })
      .delete();
  }

  sendTestEmail(email: DWS.TestTransactionalEmail): Observable<void> {
    return resource('crm://transactional-emails/send-test')
      .post<void>(email);
  }
}
